var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('my-card',{attrs:{"title":"Daftar Jenis Biaya"}},[_c('template',{slot:"button"},[(_vm.cP(49))?_c('button-add',{attrs:{"to":{name: 'expenses-add'},"variant":"primary"}}):_vm._e()],1),_c('template',{slot:"body"},[_c('data-tables',{ref:"datatables",attrs:{"endpoint":"v1/expenses","fields":_vm.fields},scopedSlots:_vm._u([{key:"1",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'font-weight-bolder': !item[6], 'ml-1':item[6]}},[_vm._v(_vm._s(item[6] ? '-' : '')+" "+_vm._s(item[1]))])]}},{key:"3",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item[3]))+" ")]}},{key:"4",fn:function(ref){
var item = ref.item;
return [(item[4]==1)?_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v("Ya")]):_c('b-badge',{attrs:{"variant":"light-warning"}},[_vm._v("Tidak")])]}},{key:"5",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"float-none"},[(_vm.cP(50))?_c('button-link',{attrs:{"size":"sm","to":{name:'expenses-edit',params:{id: item[5]}},"feather-icon":"EditIcon"}}):_vm._e(),(_vm.cP(51))?_c('button-delete',{attrs:{"id":item[5],"name":((item[0]) + " (" + (item[1]) + ")")},on:{"ondelete":_vm.deleteRow}}):_vm._e()],1)]}}])})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }